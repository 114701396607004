import React from 'react'

export default function Header() {
    return (
        <>
            {/* header */}
            <header id='scrollzipPoint'>
                {/* top header */}
                <div className="top-head-w3ls py-2 bg-dark">
                    <div className="container">
                        <div className="row">
                            <h1 className="text-capitalize text-white col-7">
                                <i className="fa fa-book text-dark bg-white p-2 rounded-circle mr-3" />
                                Welcome to SCAI School Management AI Software
                            </h1>
                            {/* social icons */}
                            <div className="social-icons text-right col-5">
                                <ul className="list-unstyled">
                                    <li>
                                        <a
                                            href="https://arjtechnologies.com/" target='_blank'
                                            className="fa fa-google icon-border facebook rounded-circle"
                                        >
                                            {" "}
                                        </a>
                                    </li>
                                    <li className="mx-2">
                                        <a
                                            href="https://www.linkedin.com/company/arj-technologies/" target='_blank'
                                            className="fa fa-linkedin icon-border twitter rounded-circle"
                                        >
                                            {" "}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/arj_technologies/" target='_blank'
                                            className="fa fa-instagram icon-border googleplus rounded-circle"
                                        >
                                            {" "}
                                        </a>
                                    </li>
                                    <li className="ml-2">
                                        <a
                                            href="#"
                                            className="fa fa-facebook icon-border rss rounded-circle"
                                        >
                                            {" "}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {/* //social icons */}
                        </div>
                    </div>
                </div>
                {/* //top header */}
                {/* middle header */}


                <nav className="navbar navbar-expand-sm bg-light">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">
                            <img src="assets/images/scai_weblogo.png" width="200" alt="" />
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsibleNavbar"
                            aria-controls="collapsibleNavbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a className="nav-link" href="mailto:arjtechnologiesofficial@gmail.com">
                                        <i className="fa fa-envelope"></i>
                                        <span className="fs-6 d-inline d-sm-none d-md-inline">
                                            arjtechnologiesofficial@gmail.com
                                        </span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">
                                        <i className="fa fa-phone"></i>
                                        <span className="d-inline d-sm-none d-md-inline">+91 7733095696</span>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <button className="nav-link bttn btn btn-primary btn-sm" data-bs-toggle="modal"
                                        data-bs-target="#exampleModal" >Login</button>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
                {/* //middle header */}
            </header>
            {/* //header */}
        </>
    )
}
