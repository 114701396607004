import React, { useState, useEffect } from 'react'
import Footer from '../main/Footer'
import Header from '../main/Header'
import Slider from "react-slick";
export default function Home() {
    var settings = {
        dots: true,
        arrows: false,
        className: "center",
        centerMode: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        cssEase: "linear",
        centerPadding: '0',
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <>
            <Header />

            {/* banner */}
            <div className="">
                <img src="assets/images/scai_banner_gif2.gif" width={'100%'} alt="" />

            </div>
            {/* //banner */}

            {/* about */}
            <div className="about">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light text-dark text-center mb-3">
                        Welcome to
                        <span className="font-weight-bold"> SCAI </span>
                    </h3>
                    <div className="row">
                        <div className="col-md-12">
                            <p className="text-center my-4 pr-4 border-right border-left">

                                Welcome to SCAI School Management AI Software your one-stop solution for seamless school management. Manage students, teachers, fees, exams, and timetables effortlessly. Streamline operations with modules like Library, Transport, and HR. Stay connected through portals, bulk SMS, and real-time updates!
                            </p>
                        </div>
                    </div>
                    <div className="row pt-md-4">
                        <div className="col-lg-6 about_right">
                            <h3 className="text-capitalize text-left font-weight-light font-italic">
                                interface friendly learning at
                                <span className="font-weight-bold">SCAI  Software</span>
                            </h3>
                            <div className="about_left-list border-right border-primary border-3">
                                <h6 className="mb-lg-3 mb-2 font-weight-bold text-dark">
                                    Our Benefits
                                </h6>
                                <ul className="list-unstyled">
                                    <li className="mb-2">
                                        <i className="fa fa-check mr-3" />
                                        Streamlined Administration
                                    </li>
                                    <li className="mb-2">
                                        <i className="fa fa-check mr-3" />
                                        Real-time Updates
                                    </li>
                                    <li className="mb-2">
                                        <i className="fa fa-check mr-3" />
                                        Data Security & Backup
                                    </li>
                                    <li className="mb-2">
                                        <i className="fa fa-check mr-3" />
                                        Customizable Features
                                    </li>
                                    <li className="mb-2">
                                        <i className="fa fa-check mr-3" />
                                        Efficient Attendance Tracking
                                    </li>
                                </ul>
                            </div>


                        </div>
                        <div className="col-lg-6 left-img-agikes mt-lg-0 mt-sm-4 mt-3 text-right">
                            <img src="assets/images/scai_mac2.png" alt="" className="img-fluid " />
                            <img src="assets/images/scai_course.png" className='ani1 vert-move ' width={"50%"} alt="" />
                            <img src="assets/images/scai_enquery.png" className='ani3 vert-move ' width={"50%"} alt="" />
                            <img src="assets/images/scai_login.png" className='ani2 vert-move ' width={"50%"} alt="" />
                            {/* <div className="about-bottom text-center p-sm-5 p-4">
                                <ul>
                                    <li>
                                        <h5>60+</h5>
                                        <p className="text-dark font-weight-bold">Teachers</p>
                                    </li>
                                    <li>
                                        <h5>2000+</h5>
                                        <p className="text-dark font-weight-bold">Students</p>
                                    </li>
                                    <li>
                                        <h5>80+</h5>
                                        <p className="text-dark font-weight-bold">Courses</p>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* //about */}
            {/*-728x90-*/}
            {/* Stats*/}
            <div className="stats-w3layouts py-5">
                <div className="container py-xl-5 py-lg-3">
                    <div className="stats-info">
                        <div className="row">
                            <div className="col-md-3 col-6 stats-grid-w3-agile text-center px-xl-5 px-3">
                                <div
                                    className="numscroller font-weight-bold my-2"
                                    data-slno={1}
                                    data-min={0}
                                    data-max={1280}
                                    data-delay=".5"
                                    data-increment={1}
                                >
                                    1280
                                </div>
                                <p className="text-uppercase text-white border-top pt-4 mt-3">
                                    happy students
                                </p>
                            </div>
                            <div className="col-md-3 col-6 stats-grid-w3-agile text-center  px-xl-5 px-3">
                                <div
                                    className="numscroller font-weight-bold my-2"
                                    data-slno={1}
                                    data-min={0}
                                    data-max={920}
                                    data-delay=".5"
                                    data-increment={1}
                                >
                                    920
                                </div>
                                <p className="text-uppercase text-white border-top pt-4 mt-3">
                                    approved courses
                                </p>
                            </div>
                            <div className="col-md-3 col-6 stats-grid-w3-agile text-center mt-md-0 mt-5  px-xl-5 px-3">
                                <div
                                    className="numscroller font-weight-bold my-2"
                                    data-slno={1}
                                    data-min={0}
                                    data-max={1200}
                                    data-delay=".5"
                                    data-increment={1}
                                >
                                    1200
                                </div>
                                <p className="text-uppercase text-white border-top pt-4 mt-3">
                                    certified teachers
                                </p>
                            </div>
                            <div className="col-md-3 col-6 stats-grid-w3-agile text-center mt-md-0 mt-5  px-xl-5 px-3">
                                <div
                                    className="numscroller font-weight-bold my-2"
                                    data-slno={1}
                                    data-min={0}
                                    data-max={1200}
                                    data-delay=".5"
                                    data-increment={1}
                                >
                                    1200
                                </div>
                                <p className="text-uppercase text-white border-top pt-4 mt-3">
                                    graduate students
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* //Stats */}
            {/*-728x90-*/}
            {/* course*/}
            <div className="classes mt-5">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light text-dark text-center mb-sm-5 mb-4 mt-5" >
                        SCAI Software
                        <span className="font-weight-bold"> Module</span>
                    </h3>
                    <div className="row pt-4">
                        <div className="col-lg-4 col-sm-4 mt-5 col-6 col-news-top text-center">
                            {/* Left to right */}
                            {/* normal */}
                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="/">
                                    <div className="img">
                                        <img
                                            src="https://st3.depositphotos.com/3591429/15088/i/450/depositphotos_150880794-stock-photo-diversity-friends-sits-near-wall.jpg"
                                            alt="img"
                                            className="img-fluid h-100 rounded-circle"
                                        />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">Students Management</h3>
                                        <p>Empower. Organize. Succeed. </p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="/" className="text-dark">
                                    Students Management

                                </a>
                            </h6>
                            {/* end normal */}
                            {/* end Left to right */}
                        </div>
                        <div className="col-lg-4 col-sm-4 mt-5 col-6 col-news-top text-center ">
                            {/* Left to right */}
                            {/* normal */}
                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="/">
                                    <div className="img">
                                        <img
                                            src="https://st2.depositphotos.com/3591429/11211/i/450/depositphotos_112112776-stock-photo-people-with-digital-gadgets.jpg"
                                            alt="img"
                                            className="img-fluid h-100 rounded-circle"
                                        />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">Staff Managment</h3>
                                        <p> Lead |
                                            Plan | Motivate | Achieve</p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="/" className="text-dark">
                                    Staff Managment

                                </a>
                            </h6>
                            {/* end normal */}
                            {/* end Left to right */}
                        </div>
                        <div className="col-lg-4 col-sm-4 mt-5 col-6  col-news-top text-center">
                            {/* Left to right */}
                            {/* normal */}
                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="/">
                                    <div className="img">
                                        <img
                                            src="https://www.pcloudy.com/wp-content/uploads/2024/08/Thumbnail-369x300-1.png"
                                            alt="img"
                                            className="img-fluid h-100 rounded-circle"
                                        />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">Test Reports</h3>
                                        <p>Insightful Results
                                        </p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="/" className="text-dark">
                                    Test Reports

                                </a>
                            </h6>
                            {/* end normal */}
                            {/* end Left to right */}
                        </div>

                        <div className="col-lg-4 col-sm-4 mt-5 col-6 col-news-top text-center">
                            {/* Left to right */}
                            {/* normal */}
                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="/">
                                    <div className="img">
                                        <img
                                            src="https://decentro.tech/blog/wp-content/uploads/Executing_Payroll.jpeg"
                                            alt="img"
                                            className="img-fluid h-100 rounded-circle"
                                        />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">Payroll Management</h3>
                                        <p>Efficient, Accurate, Simplified</p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="/" className="text-dark">
                                    Payroll Management

                                </a>
                            </h6>
                            {/* end normal */}
                            {/* end Left to right */}
                        </div>
                        <div className="col-lg-4 col-sm-4 col-6 mt-5 col-news-top text-center  ">
                            {/* Left to right */}
                            {/* normal */}
                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="social_media.html">
                                    <div className="img">
                                        <img
                                            src="https://dutypar.com/wp-content/uploads/2023/02/How-to-calculate-monthly-average-attendance-1.jpg"
                                            alt="img"
                                            className="img-fluid h-100 rounded-circle"
                                        />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">Attendance managment</h3>
                                        <p>Track. Manage. Simplify.</p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="social_media.html" className="text-dark">
                                    Attendance managment

                                </a>
                            </h6>
                            {/* end normal */}
                            {/* end Left to right */}
                        </div>
                        <div className="col-lg-4 col-sm-4 col-6 mt-5 col-news-top text-center">
                            {/* Left to right */}
                            {/* normal */}
                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="photography.html">
                                    <div className="img">
                                        <img
                                            src="https://www.ntsplhosting.com/blog/wp-content/uploads/2021/01/need-of-bulk-sms-for-business-growth.jpg"
                                            alt="img"
                                            className="img-fluid h-100 rounded-circle"
                                        />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">Bulk SMS</h3>
                                        <p>Fast. Direct. Impactful.</p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="photography.html" className="text-dark">
                                    Bulk SMS

                                </a>
                            </h6>
                            {/* end normal */}
                            {/* end Left to right */}
                        </div>
                    </div>
                </div>
            </div>
            {/* //course */}
            {/* what we do */}
            <div className="why-choose-agile py-5">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light  text-center mb-5">
                        Preview of
                        <span className="font-weight-bold"> SCAI </span>
                        Software
                    </h3>
                    <div className="row">
                        <div className="col-md-4 mt-2 d-flex justify-content-center">
                            <div className="Scai_preview card p-3 item">
                                <span className="descr">Profile Managmenet</span>
                                <img src="assets/images/01.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-4 mt-2 d-flex justify-content-center">
                            <div className="Scai_preview card p-3 item">
                                <span className="descr">Attendance Management </span>
                                <img src="assets/images/02.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-4 mt-2 d-flex justify-content-center">
                            <div className="Scai_preview card p-3 item">
                                <span className="descr">Enquery Managmenet</span>
                                <img src="assets/images/03.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-4 mt-2 d-flex justify-content-center">
                            <div className="Scai_preview card p-3 item">
                                <span className="descr">SCAI Dashbord</span>
                                <img src="assets/images/04.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-4 mt-2 d-flex justify-content-center">
                            <div className="Scai_preview card p-3 item">
                                <span className="descr">Fee Managment</span>
                                <img src="assets/images/05.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-4 mt-2 d-flex justify-content-center">
                            <div className="Scai_preview card p-3 item">
                                <span className="descr">Course Managment</span>
                                <img src="assets/images/06.png" alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* //what we do */}
            {/* video and events */}
            {/* <div className="video-choose-agile py-5">
                <div className="container py-xl-5 py-lg-3">
                    <div className="row">
                        <div className="col-lg-7 video">
                            <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">
                                our
                                <span className="font-weight-bold">video</span>
                            </h3>
                            <iframe src="https://player.vimeo.com/video/58123228" />
                        </div>
                        <div className="col-lg-5 events">
                            <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">
                                upcoming
                                <span className="font-weight-bold">events</span>
                            </h3>
                            <div className="events-w3ls">
                                <div className="d-flex">
                                    <div className="col-sm-2 col-3 events-up p-3 text-center">
                                        <h5 className="text-white font-weight-bold">
                                            28
                                            <span className="border-top font-weight-light pt-2 mt-2">
                                                May
                                            </span>
                                        </h5>
                                    </div>
                                    <div className="col-sm-10 col-9 events-right">
                                        <h4 className="text-dark">Admission Fair Spring </h4>
                                        <ul className="list-unstyled">
                                            <li className="my-2">
                                                <i className="far fa-clock mr-2" />
                                                5.00 pm - 7.30 pm
                                            </li>
                                            <li>
                                                <i className="fas fa-map-marker mr-2" />
                                                25 Newyork City.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex my-4">
                                <div className="col-sm-2 col-3 events-up p-3 text-center">
                                    <h5 className="text-white font-weight-bold">
                                        28
                                        <span className="border-top font-weight-light pt-2 mt-2">
                                            May
                                        </span>
                                    </h5>
                                </div>
                                <div className="col-sm-10 col-9 events-right">
                                    <h4 className="text-dark">Admission Fair Spring </h4>
                                    <ul className="list-unstyled">
                                        <li className="my-2">
                                            <i className="far fa-clock mr-2" />
                                            5.00 pm - 7.30 pm
                                        </li>
                                        <li>
                                            <i className="fas fa-map-marker mr-2" />
                                            25 Newyork City.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-sm-2 col-3 events-up p-3 text-center">
                                    <h5 className="text-white font-weight-bold">
                                        28
                                        <span className="border-top font-weight-light pt-2 mt-2">
                                            May
                                        </span>
                                    </h5>
                                </div>
                                <div className="col-sm-10 col-9 events-right">
                                    <h4 className="text-dark">Admission Fair Spring </h4>
                                    <ul className="list-unstyled">
                                        <li className="my-2">
                                            <i className="far fa-clock mr-2" />
                                            5.00 pm - 7.30 pm
                                        </li>
                                        <li>
                                            <i className="fas fa-map-marker mr-2" />
                                            25 Newyork City.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* //video and events */}
            {/* testimonials */}
            <div className="testimonials py-5">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light text-white text-center mb-5">
                        what our
                        <span className="font-weight-bold">people says</span>
                    </h3>
                    <Slider {...settings}>
                        {/* <div>
                            <div className="card px-3 py-3 rounded ">
                                <div className='text-center'>
                                    <i className="fa fa-quote-right" aria-hidden="true"></i>
                                    <p>"SCAI has transformed how we manage our school. It's efficient, intuitive, and a game-changer for us."</p>
                                </div>
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                                    <img
                                        src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                        alt="Icon"
                                        width={100}
                                    />
                                </div>
                                <h3 className='text-center'>Rahul</h3>
                            </div>
                        </div>
                        <div>
                            <div className="card px-3 py-3 rounded ">
                                <div className='text-center'>
                                    <i className="fa fa-quote-right" aria-hidden="true"></i>
                                    <p>"SCAI has transformed how we manage our school. It's efficient, intuitive, and a game-changer for us."

                                    </p>
                                </div>
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                                    <img
                                        src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                        alt="Icon"
                                        width={100}
                                    />
                                </div>
                                <h3 className='text-center'>Rahul</h3>
                            </div>
                        </div>
                        <div>
                            <div className="card px-3 py-3 rounded ">
                                <div className='text-center'>
                                    <i className="fa fa-quote-right" aria-hidden="true"></i>
                                    <p>"With SCAI, managing daily school tasks has never been this seamless. Highly recommend it!"</p>
                                </div>
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                                    <img
                                        src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                        alt="Icon"
                                        width={100}
                                    />
                                </div>
                                <h3 className='text-center'>Rahul</h3>
                            </div>
                        </div> */}
                        <div>
                            <div className="card px-3 py-3 rounded ">
                                <div className='text-center'>
                                    <i className="fa fa-quote-right" aria-hidden="true"></i>
                                    <p>"SCAI School Management Software simplifies everything from attendance to fee tracking. It's a lifesaver!"</p>
                                </div>
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                                    <img
                                        src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                        alt="Icon"
                                        width={100}
                                    />
                                </div>
                                <h3 className='text-center'>Utthan Career Institute</h3>
                            </div>
                        </div>
                        <div>
                            <div className="card px-3 py-3 rounded ">
                                <div className='text-center'>
                                    <i className="fa fa-quote-right" aria-hidden="true"></i>
                                    <p>"Our school’s administration is more organized and efficient thanks to SCAI. It’s the perfect solution!"</p>
                                </div>
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                                    <img
                                        src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                        alt="Icon"
                                        width={100}
                                    />
                                </div>
                                <h3 className='text-center'>Ubgot Academy </h3>
                            </div>
                        </div>
                        <div>
                            <div className="card px-3 py-3 rounded ">
                                <div className='text-center'>
                                    <i className="fa fa-quote-right" aria-hidden="true"></i>
                                    <p>"SCAI provides everything we need to run our school smoothly. It’s reliable and incredibly easy to use."</p>
                                </div>
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                                    <img
                                        src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                        alt="Icon"
                                        width={100}
                                    />
                                </div>
                                <h3 className='text-center'>Arj Institute Of Technology</h3>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            {/* //testimonials */}
            {/* news */}
            <div className="news-section py-5">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">
                        A one-stop
                        <span className="font-weight-bold"> Solution </span>for
                    </h3>
                    <div className="row">
                        <div className="col-md-4 mt-2">
                            <div className="card p-4">
                                <div className='img_logo'>
                                    <img src="https://img.freepik.com/premium-vector/book-logo-template-design-education-icon-sign-symbol_752732-614.jpg" alt="" />
                                </div>
                                <h3 className='text-center'>School</h3>
                            </div>
                        </div>
                        <div className="col-md-4 mt-2">
                            <div className="card p-4">
                                <div className='img_logo'>
                                    <img src="https://media.istockphoto.com/id/1475335566/vector/university-logo-design-vector.jpg?s=612x612&w=0&k=20&c=38yhifr8INb_gaS4C7WJ783XhJoK4YnnInwsmskH1ww=" alt="" />
                                </div>
                                <h3 className='text-center'>Colleges</h3>
                            </div>
                        </div>
                        <div className="col-md-4 mt-2">
                            <div className="card p-4">
                                <div className='img_logo'>
                                    <img src="https://t4.ftcdn.net/jpg/02/51/49/57/360_F_251495755_xWdqsPQavPBZA6G2n8Oslw9GDWtc1SDp.jpg" alt="" />
                                </div>
                                <h3 className='text-center'>Institute</h3>
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <div className="card">
                                <div className='img_logo'>
                                    <img src="https://img.freepik.com/premium-vector/book-logo-template-design-education-icon-sign-symbol_752732-614.jpg" alt="" />
                                </div>
                                <h3 className='text-center'>School</h3>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* //news */}
            {/* brands */}
            <div className="brands-w3ls py-md-5 py-4">
                <div className="container py-xl-3">
                    <marquee behavior="" direction="">
                        <ul className="list-unstyled">
                            <li>
                                <img src="assets/images/utthan.png" width={"300px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/ubgot.png" width={"230px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/arj_institute.png" width={"270px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/utthan.png" width={"300px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/ubgot.png" width={"230px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/arj_institute.png" width={"270px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/utthan.png" width={"300px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/ubgot.png" width={"230px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/arj_institute.png" width={"270px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/utthan.png" width={"300px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/ubgot.png" width={"230px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/arj_institute.png" width={"270px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/utthan.png" width={"300px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/ubgot.png" width={"230px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/arj_institute.png" width={"270px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/utthan.png" width={"300px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/ubgot.png" width={"230px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/arj_institute.png" width={"270px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/utthan.png" width={"300px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/ubgot.png" width={"230px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/arj_institute.png" width={"270px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/utthan.png" width={"300px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/ubgot.png" width={"230px"} alt="" />
                            </li>
                            <li>
                                <img src="assets/images/arj_institute.png" width={"270px"} alt="" />
                            </li>



                        </ul>
                    </marquee>
                </div>
            </div>
            {/* //brands */}
            <Footer />
        </>

    )
}
